import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-first-section',
  templateUrl: './first-section.component.html',
  styleUrls: ['./first-section.component.scss']
})
export class FirstSectionComponent implements OnInit {

  videoshow = false;

  @ViewChild('bunnyVideo', { read: ElementRef, static: false }) bunnyVideo: ElementRef;

  constructor() { }

  ngOnInit() {
    const innerWidth = window.innerWidth;
    if(window.innerWidth > 990){
      this.videoshow = true;
    }
  }

  ngAfterViewInit() {
    const video: HTMLVideoElement = this.bunnyVideo.nativeElement;
    video.muted = true;
  }

}
