export class Customer {
  public id: string;

  public created: number;

  public email: string;

  public name: string;

  public phone: string;

  constructor(public customer: any) {
    this.id = customer.id;
    this.created = customer.created;
    this.email = customer.email;
    this.name = customer.name;
    this.phone = customer.phone;
  }

  public get() {
    return {
      id: this.id,
      created: this.created,
      email: this.email,
      name: this.name,
      phone: this.phone,
    };
  }
}
