import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeElementCardComponent } from './stripe-element-card/stripe-element-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StripeElementUpdatecardComponent } from './stripe-element-updatecard/stripe-element-updatecard.component';
import { StripeComponent } from './stripe.component';
import {   MatInputModule } from '@angular/material'; 
import {MatCardModule} from '@angular/material/card';


@NgModule({
  declarations: [
    StripeElementCardComponent,
    StripeElementUpdatecardComponent,
    StripeComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule,MatInputModule,MatCardModule],
  exports: [StripeComponent, StripeElementCardComponent,MatInputModule,MatCardModule],
})
export class StripeModule {}
