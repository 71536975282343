import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-rastreo',
  templateUrl: './rastreo.component.html',
  styleUrls: ['./rastreo.component.scss']
})
export class RastreoComponent implements OnInit {

  public number_folio: FormControl = new FormControl('');

  constructor(private _general: GeneralService) {}

  ngOnInit(): void {}

  orderStatus(value: any) {
    if(!value){ 
    }else{
      this._general.openRouter('estado-orden/' + value);
    } 
  }

}
