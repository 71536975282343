import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-section2',
  templateUrl: './section2.component.html',
  styleUrls: ['./section2.component.scss']
})
export class Section2Component implements OnInit {

  constructor(
    public _data: DataService,
    public _general: GeneralService
  ) { }

  ngOnInit() {
  }

  format_date(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  }

}
