import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ArreglosService } from "src/app/services/arreglos.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalContactoComponent } from "../modal-contacto/modal-contacto.component";
import { ModalPistaComponent } from "../modal-pista/modal-pista.component";
import { ModalFolioComponent } from "../modal-folio/modal-folio.component";
import { OrdenesService } from "src/app/services/ordenes.service";
import { Observable } from "rxjs";
import { ThirdSectionArreglosComponent } from "../../arreglos/components/third-section-arreglos/third-section-arreglos.component";

@Component({
  selector: "app-first-section-ordenar",
  templateUrl: "./first-section-ordenar.component.html",
  styleUrls: ["./first-section-ordenar.component.scss"]
})
export class FirstSectionOrdenarComponent implements OnInit {
  private id;
  public arreglo: any = {
    id: "",
    nombre: "",
    ocasion: "",
    tipo: "",
    descripcion: "",
    precio: "0",
    imagen: "../../../../assets/img/sin-imagen.png",
    imagenes: ["../../../../assets/img/sin-imagen.png"],
    favorito: false,
    activo: true,
    tamano: "mediano",
    popularidad: {
      redSocial: {},
      orden: 0,
      pista: 0
    }
  };
  public adicionalesA;
  public redesSociales: Observable<any>;
  public adicionales: Observable<any>;
  public adicionalesSeleccionados = [];
  public imagenSelected = "../../../../assets/img/sin-imagen.png";
  public min = -1;
  public max = 4;
  public ordenado;

  constructor(
    private route: ActivatedRoute,
    private arreglosService: ArreglosService,
    private dialog: MatDialog,
    private ordenesServie: OrdenesService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      this.getArreglo();
    });
    this.ordenesServie.obtenerOrdenes().subscribe(data => {
      this.ordenado = data;
    });
    this.adicionales = this.arreglosService.obtenerAdicionales();
    this.arreglosService.obtenerAdicionales().subscribe(data => {
      this.adicionalesA = data;
    });
    this.redesSociales = this.arreglosService.obtenerRedesSOciales();
  }

  getArreglo() {
    this.arreglosService.obtenerArreglo(this.id).subscribe(data => {
      this.arreglo = data.data();
      this.arreglo.id = data.id;
      this.arreglo.imagenes.push(this.arreglo.imagen);
      this.imagenSelected = this.arreglo.imagen;
    });
  }

  change(data) {
    if (data === "next") {
      this.min++;
      this.max++;
    } else {
      this.min--;
      this.max--;
    }
  }

  openModalContacto() {
    const dialogRef = this.dialog.open(ModalContactoComponent, {
      panelClass: "custom-modalbox",
      data: {
        arreglo: this.arreglo,
        adicionales: this.adicionalesSeleccionados
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openModalFolio(result);
      }
    });
  }

  openModalFolio(result) {
    const dialogRef = this.dialog.open(ModalFolioComponent, {
      panelClass: "custom-modalbox",
      data: { arreglo: this.arreglo, orden: result }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  openModalPista() {
    const dialogRef = this.dialog.open(ModalPistaComponent, {
      panelClass: "custom-modalbox-pista",
      data: { arreglo: this.arreglo }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  guardarAdicional(data) {
    if (!data.selected) {
      data.selected = true;
      this.adicionalesSeleccionados.push(data.id);
    } else {
      data.selected = false;
      this.adicionalesSeleccionados = this.adicionalesSeleccionados.filter(
        adicional => adicional !== data.id
      );
    }
  }

  shared(data) {
    this.ordenesServie.compartir(this.arreglo, data);
    switch (data) {
      case "whatsapp":
        this.whatsapp();
        break;
      case "twitter":
        this.twitter();
        break;
      case "facebook":
        this.facebook();
        break;
    }
  }

  facebook() {
    const data = "https://www.facebook.com/sharer/sharer.php?u=";
    this.sharedPage(data);
  }

  twitter() {
    const data = "https://twitter.com/intent/tweet?text=";
    this.sharedPage(data);
  }

  whatsapp() {
    const data = "https://wa.me/?text=";
    this.sharedPage(data);
  }

  sharedPage(data) {
    alert(location.href);
    window.open(data + location.href);
  }
}
