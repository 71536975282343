export class Subscription {
  public id: string;

  public cancel_at: any;

  public cancel_at_period_end: boolean;

  public canceled_at: any;

  public created: number;

  public current_period_end: number;

  public current_period_start: number;

  public customer: string;

  public default_payment_method: string;

  public discount: any;

  public start_date: number;

  public status: string;

  public trial_end: any;

  public trial_start: any;

  public plan_id: string;

  public plan_amount: number;

  public plan_currency: string;

  public plan_interval: string;

  public plan_product: string;

  public plan_trial_period_days: any;

  public payment_intent_id: string;

  public invoice_id: string;

  constructor(public data: any) {
    this.id = data.subscribe.id;
    this.cancel_at = data.subscribe.cancel_at;
    this.cancel_at_period_end = data.subscribe.cancel_at_period_end;
    this.canceled_at = data.subscribe.canceled_at;
    this.created = data.subscribe.created;
    this.current_period_end = data.subscribe.current_period_end;
    this.current_period_start = data.subscribe.current_period_start;
    this.customer = data.subscribe.customer;
    this.default_payment_method = data.subscribe.default_payment_method;
    this.discount = data.subscribe.discount;
    this.start_date = data.subscribe.start_date;
    this.status = data.subscribe.status;
    this.trial_end = data.subscribe.trial_end;
    this.trial_start = data.subscribe.trial_start;
    this.plan_id = data.subscribe.id;
    this.plan_amount = data.subscribe.amount;
    this.plan_currency = data.subscribe.currency;
    this.plan_interval = data.subscribe.interval;
    this.plan_product = data.subscribe.product;
    this.plan_trial_period_days = data.subscribe.trial_period_days;
    this.payment_intent_id = data.paymentIntent.id ? data.paymentIntent.id : '';
    this.invoice_id = data.invoice.id;
  }

  public get() {
    return {
      id: this.id,
      cancel_at: this.cancel_at,
      cancel_at_period_end: this.cancel_at_period_end,
      canceled_at: this.canceled_at,
      created: this.created,
      current_period_end: this.current_period_end,
      current_period_start: this.current_period_start,
      customer: this.customer,
      default_payment_method: this.default_payment_method,
      discount: this.discount,
      start_date: this.start_date,
      status: this.status,
      trial_end: this.trial_end,
      trial_start: this.trial_start,
      plan_id: this.plan_id,
      plan_amount: this.plan_amount,
      plan_currency: this.plan_currency,
      plan_interval: this.plan_interval,
      plan_product: this.plan_product,
      plan_trial_period_days: this.plan_trial_period_days,
      payment_intent_id: this.payment_intent_id ? this.payment_intent_id : '',
      invoice_id: this.invoice_id,
    };
  }
}
