import { Card } from './Card';

export class PaymentMethod {
  public id: string;

  public name: string;

  public brand: string;

  public exp_month: number;

  public exp_year: number;

  public funding: string;

  public last4: string;

  public created: string;

  public customer: string;

  public country: string;

  constructor(public paymentMethod: any) {
    this.id = paymentMethod.id;
    this.name = paymentMethod.billing_details.name;
    this.brand = paymentMethod.card.brand;
    this.exp_month = paymentMethod.card.exp_month;
    this.exp_year = paymentMethod.card.exp_year;
    this.funding = paymentMethod.card.funding;
    this.last4 = paymentMethod.card.last4;
    this.created = paymentMethod.created;
    this.customer = paymentMethod.customer;
    this.country = paymentMethod.card.country;
  }

  public get() {
    return {
      id: this.id,
      name: this.name,
      brand: this.brand,
      exp_month: this.exp_month,
      exp_year: this.exp_year,
      funding: this.funding,
      last4: this.last4,
      created: this.created,
      customer: this.customer,
      country: this.country,
    };
  }
}
