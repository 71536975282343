import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';
/* import { DataService } from 'src/app/services/data.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GeneralService } from 'src/app/services/general.service'; */

@Component({
  selector: 'app-checkout-general-info',
  templateUrl: './checkout-general-info.component.html',
  styleUrls: ['../../checkout/checkout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutGeneralInfoComponent implements OnInit {
  @Output() values: EventEmitter<any> = new EventEmitter();

  public complete: number = 0;

  public form: FormGroup = new FormGroup({
    nombre: new FormControl('', Validators.required),
    correo: new FormControl('', [Validators.required, Validators.email]),
    telefono: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('[0-9 +-]*'),
    ]),
  });

  public flush: string = '';

  public data: any = [];

  constructor(
    public _data: DataService,
    public _general: GeneralService 
  ) {}

  ngOnInit(): void {}

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public next() {
    if (this.form.valid) {
      this.values.emit(this.form.value);
    } else {
      this.complete = 0;
      this.form.markAllAsTouched(); 
    }
  }
  public send() {
    this._data.setDataId('info');
    this.complete = 1;
    this.values.emit(this.form.value);
  }
}
