import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router'; 
import { AnalitycsService } from './services/analitycs.service';

@Injectable({
  providedIn: 'root'
})
export class AnalitycGuard implements CanActivate {
  
  constructor(private _analitycs: AnalitycsService) {}
  
  async canActivate(next): Promise<any> {
    const url = next.url;
    this._analitycs.viewPage(url);
    return true;
  }
  
}
