import { Component, OnInit } from '@angular/core';
import { OrdenesService } from 'src/app/services/ordenes.service';
import { Observable } from 'rxjs';
import { ModalOrderComponent } from '../modal-order/modal-order.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public ordenes = [];

  public menus: Array<any> = [
    {
      name: 'Inicio',
      router: '/inicio'
    },
    {
      name: 'Arreglos',
      router: '/arreglos'
    },
    {
      name: 'Personalizados',
      router: '/personalizados'
    },
    {
      name: 'Eventos',
      router: '/eventos'
    },
    {
      name: 'Sucursales',
      router: '/sucursales'
    },
    {
      name: 'Contacto',
      router: '/contacto'
    }
  ]

  constructor(private ordenesService: OrdenesService, public _dialog: MatDialog,) { 
    ordenesService.obtenerOrdenes().subscribe(data => {
      this.ordenes = data;
    })
  }

  ngOnInit() {
  }

  copiar(val) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public openModal(): void {
    this._dialog.open(ModalOrderComponent, { 
      width: '550px',
      panelClass: 'nuevo-modal',
    });
  }

  
}
