import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DecoracionesService } from 'src/app/services/decoraciones.service';

@Component({
  selector: 'app-third-section-event',
  templateUrl: './third-section-event.component.html',
  styleUrls: ['./third-section-event.component.scss']
})
export class ThirdSectionEventComponent implements OnInit { 
 
   
  public decoraciones: Observable<Array<any>>;
  
  public countersocial: number = 0;

  public counterpopular: number = 0;

  public countermasivo: number = 0;

  public counterempresarial: number = 0;

  public counterindustrial: number = 0;

  public counterescolares: number = 0;
  
  public counteresotros: number = 0;

  public categoria : string = '';

  public counter : number = 0;

  public title : string = ''; 

  constructor(private decoracionesService: DecoracionesService) { }

  ngOnInit() {
    this.decoraciones = this.decoracionesService.obtener(); 
  }

  getData(value:any, index:any){
    this.title = ''; 
    let categoria = value.categoria[0]; 
    this.counter = index;
    this.title = categoria;   
    this.categoria  = categoria;
    console.log(categoria)
  }

}
