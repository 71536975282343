import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ArreglosComponent } from './pages/arreglos/arreglos.component';
import { DecoracionesComponent } from './pages/decoraciones/decoraciones.component';
import { SucursalesComponent } from './pages/sucursales/sucursales.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { MaterialModule } from './material';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArreglosService } from './services/arreglos.service';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header-section/header.component';
import { FooterComponent } from './components/footer-section/footer.component';
import { FirstSectionComponent } from './pages/decoraciones/components/first-section/first-section.component';
import { SecondSectionComponent } from './pages/decoraciones/components/second-section/second-section.component';
import { ContactSectionComponent } from './components/contact-section/contact-section.component';
import { EventosSectionComponent } from './components/eventos-section/eventos-section.component';
import { FirstSectionSucursalesComponent } from './pages/sucursales/components/first-section-sucursales/first-section-sucursales.component';
import { SecondSectionSucursalesComponent } from './pages/sucursales/components/second-section-sucursales/second-section-sucursales.component';
import { ThirdSectionSucursalesComponent } from './pages/sucursales/components/third-section-sucursales/third-section-sucursales.component';
import { FourthSectionSucursalesComponent } from './pages/sucursales/components/fourth-section-sucursales/fourth-section-sucursales.component';
import { FifthSectionSucursalesComponent } from './pages/sucursales/components/fifth-section-sucursales/fifth-section-sucursales.component';
import { SixthSectionSucursalesComponent } from './pages/sucursales/components/sixth-section-sucursales/sixth-section-sucursales.component';
import { FirstSectionArreglosComponent } from './pages/arreglos/components/first-section-arreglos/first-section-arreglos.component';
import { SecondSectionArreglosComponent } from './pages/arreglos/components/second-section-arreglos/second-section-arreglos.component';
import { ThirdSectionArreglosComponent } from './pages/arreglos/components/third-section-arreglos/third-section-arreglos.component';
import { PromocionSectionComponent } from './components/promocion-section/promocion-section.component';
import { FiltroPipe } from './pipes/filtro.pipe';
import { ArreglosSectionArreglosComponent } from './pages/arreglos/components/arreglos-section-arreglos/arreglos-section-arreglos.component';
import { ArreglosSectionComponent } from './components/arreglos-section/arreglos-section.component';
import { FirstSectionInicioComponent } from './pages/inicio/components/first-section-inicio/first-section-inicio.component';
import { SecondSectionInicioComponent } from './pages/inicio/components/second-section-inicio/second-section-inicio.component';
import { ThirdSectionInicioComponent } from './pages/inicio/components/third-section-inicio/third-section-inicio.component';
import { FourthSectionInicioComponent } from './pages/inicio/components/fourth-section-inicio/fourth-section-inicio.component';
import { FifthSectionInicioComponent } from './pages/inicio/components/fifth-section-inicio/fifth-section-inicio.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { FirstSectionOrdenarComponent } from './pages/ordenar/first-section-ordenar/first-section-ordenar.component';
import { ModalContactoComponent } from './pages/ordenar/modal-contacto/modal-contacto.component';
import { ModalPistaComponent } from './pages/ordenar/modal-pista/modal-pista.component';
import { OrdenarComponent } from './pages/ordenar/ordenar.component';
import { ModalFolioComponent } from './pages/ordenar/modal-folio/modal-folio.component';
import { LengthPipe } from './pipes/length.pipe';
import { EventosPipe } from './pipes/eventos.pipe';
import { TerminosComponent } from './components/terminos/terminos.component';
import { PersonalizadosComponent } from './pages/personalizados/personalizados.component';
import { ArreglosPersonalizadosSectionComponent } from './components/arreglos-personalizados-section/arreglos-personalizados-section.component';
import { FirstSectionArreglosPersonalizadosComponent } from './pages/personalizados/first-section-arreglos-personalizados/first-section-arreglos-personalizados.component';
import { ArreglosSectionArreglosPersonalizadosComponent } from './pages/personalizados/arreglos-section-arreglos-personalizados/arreglos-section-arreglos-personalizados.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { FirstSectionEventComponent } from './pages/eventos/components/first-section-event/first-section-event.component';
import { SecondSectionEventComponent } from './pages/eventos/components/second-section-event/second-section-event.component';
import { ThirdSectionEventComponent } from './pages/eventos/components/third-section-event/third-section-event.component';
import { ContactSectionEventComponent } from './pages/eventos/components/contact-section-event/contact-section-event.component';
import { FourSectionEventComponent } from './pages/eventos/components/four-section-event/four-section-event.component';
import { OrdenarCompraComponent } from './pages/ordenar-compra/ordenar-compra.component';
import { FirstSectionOrdenarCompraComponent } from './pages/ordenar-compra/first-section-ordenar-compra/first-section-ordenar-compra.component';
import { CheckoutComponent } from './pages/checkout/checkout.component'; 
import { CheckoutConfirmationComponent } from './pages/checkout/checkout-confirmation/checkout-confirmation.component';
import { CheckoutShippingAddressComponent } from './pages/checkout/checkout-shipping-address/checkout-shipping-address.component';
import { CheckoutGeneralInfoComponent } from './pages/checkout/checkout-general-info/checkout-general-info.component';
import { CheckoutSuccesComponent } from './pages/checkout-succes/checkout-succes.component';
import { Section1Component } from './pages/checkout-succes/section1/section1.component';
import { Section2Component } from './pages/checkout-succes/section2/section2.component';
import { RastreoComponent } from './pages/rastreo/rastreo.component';
import { EstadoOrdenComponent } from './pages/estado-orden/estado-orden.component';
import { ArregloscompraService } from './services/arregloscompra.service';
import { StripeModule } from './stripe/stripe.module';
import { AngularFireFunctionsModule } from '@angular/fire/functions';   
import { CommonModule } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { ModalExtrasComponent } from './pages/ordenar-compra/modal-extras/modal-extras.component'; 
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ModalOrderComponent } from './components/modal-order/modal-order.component'; 

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ArreglosComponent,
    DecoracionesComponent,
    SucursalesComponent,
    ContactoComponent,
    OrdenarComponent,
    HeaderComponent,
    FooterComponent,
    FirstSectionComponent,
    SecondSectionComponent,
    ContactSectionComponent,
    EventosSectionComponent,
    FirstSectionSucursalesComponent,
    SecondSectionSucursalesComponent,
    ThirdSectionSucursalesComponent,
    FourthSectionSucursalesComponent,
    FifthSectionSucursalesComponent,
    SixthSectionSucursalesComponent,
    FirstSectionArreglosComponent,
    SecondSectionArreglosComponent,
    ThirdSectionArreglosComponent,
    PromocionSectionComponent,
    ArreglosSectionArreglosComponent,
    FiltroPipe,
    ArreglosSectionComponent,
    FirstSectionInicioComponent,
    SecondSectionInicioComponent,
    ThirdSectionInicioComponent,
    FourthSectionInicioComponent,
    FifthSectionInicioComponent,
    FirstSectionOrdenarComponent,
    ModalContactoComponent,
    ModalPistaComponent,
    ModalFolioComponent,
    LengthPipe,
    EventosPipe,
    TerminosComponent,
    PersonalizadosComponent,
    ArreglosPersonalizadosSectionComponent,
    FirstSectionArreglosPersonalizadosComponent,
    ArreglosSectionArreglosPersonalizadosComponent,
    EventosComponent,
    FirstSectionEventComponent,
    SecondSectionEventComponent,
    ThirdSectionEventComponent,
    ContactSectionEventComponent,
    FourSectionEventComponent,
    OrdenarCompraComponent,
    FirstSectionOrdenarCompraComponent,
    CheckoutComponent, 
    CheckoutConfirmationComponent,
    CheckoutShippingAddressComponent,
    CheckoutGeneralInfoComponent,
    CheckoutSuccesComponent,
    Section1Component,
    Section2Component,
    RastreoComponent,
    EstadoOrdenComponent,
    SafePipe,
    ModalExtrasComponent,
    ModalOrderComponent, 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgxSpinnerModule,
    StripeModule,
    AngularFireFunctionsModule,
    CommonModule,
    AutocompleteLibModule,  
  ],
  exports: [
    AutocompleteLibModule, 
  ],
  providers: [
    ArreglosService,
    ArregloscompraService,
    StripeModule,
    AutocompleteLibModule,
    
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    ModalContactoComponent,
    ModalPistaComponent,
    ModalFolioComponent,
    ModalExtrasComponent,
    ModalOrderComponent,
  ]
})
export class AppModule { }
