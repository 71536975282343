import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ArreglosService } from 'src/app/services/arreglos.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public redesSociales: Observable<any>;

  public menus: Array<any> = [
    {
      name: 'Inicio',
      router: '/inicio'
    },
    {
      name: 'Decoraciones',
      router: '/decoraciones'
    },
    {
      name: 'Rastrear Orden',
      router: '/rastrear-pedido'
    },
    {
      name: 'Arreglos',
      router: '/arreglos'
    },
    {
      name: 'Sucursales',
      router: '/'
    },
    {
      name: 'Contacto',
      router: '/contacto'
    },
    {
      name: 'Diseña tu propio arreglo',
      router: '/personalizados'
    },
    {
      name: 'Aviso de privacidad',
      router: '/avisodeprivacidad'
    }
  ]



  constructor(private arreglosService: ArreglosService) { 
    this.redesSociales = arreglosService.obtenerRedesSOciales();
  }

  ngOnInit() {
  }

}
