import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material"; 
import { Observable } from "rxjs";
import { ArregloscompraService } from "src/app/services/arregloscompra.service";
import { DataService } from "src/app/services/data.service"; 

@Component({
  selector: "app-modal-extras",
  templateUrl: "./modal-extras.component.html",
  styleUrls: ["./modal-extras.component.scss"],
})
export class ModalExtrasComponent implements OnInit {  
  public adicionales: Observable<any>;
  public adicionalesSeleccionados = [];
  
  constructor(
    public dialogRef: MatDialogRef<ModalExtrasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private arreglosService: ArregloscompraService, 
    private _data: DataService, 
  ) {}

  ngOnInit(): void {
    this.adicionales = this.arreglosService.obtenerAdicionales(); 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cancelar() {
    this.dialogRef.close();
  }

  guardarAdicional(data) {
    if (!data.selected) {
      data.selected = true;
      this._data.adicionalesSeleccionados.push({
        id: data.id,
        imagen: data.imagen,
        nombre: data.nombre,
        precio: data.precio,
      });
    } else {
      data.selected = false;
      this._data.adicionalesSeleccionados.splice( data.selected, 1);  
      this._data.adicionalesSeleccionados = this._data.adicionalesSeleccionados.filter(
        (adicional) => adicional !== data.id
      );
    }
    console.log(this._data.adicionalesSeleccionados)
  }
}
