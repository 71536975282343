import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-folio',
  templateUrl: './modal-folio.component.html',
  styleUrls: ['./modal-folio.component.scss']
})
export class ModalFolioComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<ModalFolioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    }

  ngOnInit() {
  }

  copiar(val) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  cancelar() {
    this.dialogRef.close();
  }

}
