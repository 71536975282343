export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAm8O8nSgENe0hRfZ26uAHiGGZ1sTTUXus",
    authDomain: "tulioos-prod.firebaseapp.com",
    projectId: "tulioos-prod",
    storageBucket: "tulioos-prod.appspot.com",
    messagingSenderId: "595858848786",
    appId: "1:595858848786:web:e0dc86c0bae4cf20542ccb",
    measurementId: "G-E29XX0MRFR",
  },
  stripePK:
    "pk_live_51KtEDAKnIpRIcn8NILArMR68h9HbVOzZLQyKcn93kuXTFSkRbRP1u91oJtJRsSg7WuEXvIlPSBnBcqnjv75mD1HX00dUalDmcy",
};
