import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { SucursalesService } from 'src/app/services/sucursales.service';

@Component({
  selector: 'app-second-section-sucursales',
  templateUrl: './second-section-sucursales.component.html',
  styleUrls: ['./second-section-sucursales.component.scss']
})
export class SecondSectionSucursalesComponent implements OnInit {

  public sucursales: Observable<any>

  @ViewChild('target', {static: false}) MyProp: ElementRef;

  constructor(private sucursalesService: SucursalesService) { }

  ngOnInit() {
    this.sucursales = this.sucursalesService.obtener();
  }
  
  scrollDown() {
    this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

}
