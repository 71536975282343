import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ProspectosService } from "src/app/services/prospectos.service";

@Component({
  selector: 'app-contact-section-event',
  templateUrl: './contact-section-event.component.html',
  styleUrls: ['./contact-section-event.component.scss']
})
export class ContactSectionEventComponent implements OnInit {

  @Input() contacto: boolean;
  public tab = 1;

  public formProspecto: FormGroup;

  constructor(private prospectoService: ProspectosService) {}

  ngOnInit() {
    if(this.contacto){
      this.createForm();
    } else {
      this.createForm3();
      this.tab = 5;
    }
  }

  enviarProspecto() {
    const tipo = this.tab === 1 ? 'duda/comentario' : this.tab === 2 ? 'sugerencia/queja' : this.tab === 3 ? 'negocios': this.tab === 4 ? 'franquicias' : 'prospectos';
    const {
      nombre,
      empresa,
      email,
      mensaje,
      telefono
    } = this.formProspecto.value;
    const prospecto = {
      nombre: nombre,
      email: email,
      telefono: telefono,
      empresa: empresa ? empresa: '',
      mensaje: mensaje,
      tipo: tipo
    };
    this.prospectoService.guardarProspecto(prospecto);
    this.formProspecto.reset();
  }

  changeTab(e) {
    e++;
    this.tab = e;
    switch (e) {
      case 1:
        this.createForm();
        break;
      case 2:
        this.createForm2();
        break;
      case 3:
        this.createForm3();
        break;
      case 4:
        this.createForm4();
        break;
    }
  }

  createForm() {
    this.formProspecto = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      mensaje: new FormControl("", [Validators.required]),
      telefono: new FormControl("")
    });
  }
  createForm2() {
    this.formProspecto = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      mensaje: new FormControl("", [Validators.required]),
      telefono: new FormControl("")
    });
  }
  createForm3() {
    this.formProspecto = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      empresa: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      mensaje: new FormControl("", [Validators.required]),
      telefono: new FormControl("")
    });
  }
  createForm4() {
    this.formProspecto = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      empresa: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      mensaje: new FormControl("", [Validators.required]),
      telefono: new FormControl("")
    });
  }
  
  

}
