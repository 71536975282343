import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';
/* import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service'; */ 

@Component({
  selector: 'app-checkout-confirmation',
  templateUrl: './checkout-confirmation.component.html',
  styleUrls: ['./checkout-confirmation.component.scss'],
})
export class CheckoutConfirmationComponent implements OnInit {
  @Input() data: any;

  public complete: number = 0; 

  constructor(public _data: DataService, public _general: GeneralService) {}

  ngOnInit(): void {  
  } 

  format_date(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  } 
}
