import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";


@Injectable({
  providedIn: 'root'
})
export class ArreglosService {

  public arreglos = [];
  public adicionales = [];
  public lastDocument;
  public canGetMoreDocuments = true;

  constructor(private db: AngularFirestore, private http: HttpClient,private spinner: NgxSpinnerService) { 
    /* this.descargarArreglos(); */
    this.descargarAdicionales();
    this.getArreglosFavoritos();
  }

  getFirstPagination(limit){
    const first = this.db.collection('arreglos').ref.orderBy("popularidad.orden", "desc").limit(limit);
    this.getData(first);
  }

  getNextPagination(){
    if(this.canGetMoreDocuments){
      const next = this.db.collection('arreglos').ref.orderBy("popularidad.orden", "desc").startAfter(this.lastDocument).limit(12);
      this.getData(next);
    }
  }

  getData(ref){
    ref.get().then(documentSnapshots => {
      if(documentSnapshots.empty){
        this.canGetMoreDocuments = false;
      } else {
        this.lastDocument = documentSnapshots.docs[documentSnapshots.docs.length-1];
        documentSnapshots.forEach(doc => {
          const exists = this.arreglos.findIndex(a => a.id === doc.id);
          if(exists < 0){
            const data = doc.data();
            data.id = doc.id;
            data.sumaPopularidad = data.popularidad.orden + data.popularidad.pista + data.popularidad.shared;
            this.arreglos.push(data);
          }
        });
      }
    });
  }

  getAllArreglos(){
    if(this.canGetMoreDocuments){
      const next = this.db.collection('arreglos').ref.orderBy("popularidad.orden", "desc").startAfter(this.lastDocument);
      this.getData(next);
      this.canGetMoreDocuments = false;
    }
  }

  getArreglosFavoritos(){
    this.spinner.show();
    this.db.collection('arreglos').ref.where("favorito","==", true).get().then(data => {
      data.docs.forEach(data => {
        const exists = this.arreglos.findIndex(a => a.id === data.id);
        if(exists < 0){
          const arreglo = data.data();
          arreglo.id = data.id;
          arreglo.sumaPopularidad = arreglo.popularidad.orden + arreglo.popularidad.pista + arreglo.popularidad.shared;
          this.arreglos.push(arreglo);
        }
      });
    }).then(() => {
      this.spinner.hide();
      let limit = 12;
      if(this.arreglos.length > 10){
        limit = 2;
      } else {
        limit = limit - this.arreglos.length;
      }
      this.getFirstPagination(limit);
    });
  }
  

  /* descargarArreglos() {
    this.spinner.show();
    this.db.collection('arreglos').ref.get().then(data => {
      data.docs.forEach(data => {
        const arreglo = data.data();
        arreglo.id = data.id;
        arreglo.sumaPopularidad = arreglo.popularidad.orden + arreglo.popularidad.pista + arreglo.popularidad.shared;
        this.arreglos.push(arreglo);
      });
    }).finally(() => {
      this.spinner.hide();
    });
  } */

  descargarAdicionales(){
    this.spinner.show();
    this.db.collection('adicionales').ref.where('activo', '==' ,true).get().then(data => {
      data.docs.forEach(data => {
        const adicional = data.data();
        adicional.id = data.id;
        adicional.selected = false;
        this.adicionales.push(adicional);
      });
    }).finally(() => {
      this.spinner.hide();
    });
  }

  obtenerArreglos(){
    return of(this.arreglos);
  }

  obtenerAdicionales(){
    return of(this.adicionales);
  }

  obtenerArreglo(id){
    return this.db.collection('arreglos').doc(id).get();
  }

  obtenerOcasiones(){
      return this.http.get<Array<any>>('../assets/datas/ocaciones.json');
  }

  obtenerTipos(){
    return this.http.get<Array<any>>('../assets/datas/tipos.json');
  }
  
  obtenerTamanos(){
    return this.http.get<Array<any>>('../assets/datas/tamanos.json');
  }

  obtenerRedesSOciales(){
    return this.http.get<Array<any>>('../assets/datas/redesSociales.json');
  }

  actualizarArreglo(data){
    this.db.collection('arreglos').doc(data.id).update({
      popularidad: data.popularidad
    });
  }
}
