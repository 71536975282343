import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrdenesService } from 'src/app/services/ordenes.service';

@Component({
  selector: 'app-modal-contacto',
  templateUrl: './modal-contacto.component.html',
  styleUrls: ['./modal-contacto.component.scss']
})
export class ModalContactoComponent implements OnInit {

  public newOrden: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalContactoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ordenesService: OrdenesService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.newOrden = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      email: new FormControl(''),
      comentario: new FormControl(''),
    });
  }


  agregarOrden() {
    const { nombre, email, comentario } = this.newOrden.value;
    const date = new Date();
    const times: any = date.getTime();
    const newOrden = {
      nombre: nombre,
      email: email,
      comentario: comentario,
      arreglo: this.data.arreglo.id,
      adicionales: this.data.adicionales,
      folio: 'F-' + times,
      fechaCreacion: new Date(),
      leido: false
    }
    this.ordenesService.newOrden(newOrden, this.data.arreglo).finally(() => {
      this.dialogRef.close(newOrden);
    });
  }

  cancelar() {
    this.dialogRef.close();
  }

}
