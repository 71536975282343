import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public products!: any[];

  public cart: any = [];

  public cart_details: any = {
    total: 0,
    precio_unitario: 0,
  };

  public info: boolean = false;

  public shipping: boolean = false;

  public payment: boolean = false;

  public order: any;

  public tipoEnvio: string = "";

  public sucursal: any;

  public precioEnvio: number = 0;

  public adicionales: number = 0;

  public adicionalesSeleccionados = [];

  public fechaRecoleccion: any;

  public horaDeRecoleccion: any;

  constructor() {}

  public addProductToCart(data: any, adicionales: any, type: any): void {
    let product: any = JSON.parse(JSON.stringify(data));
    product.count = 1;
    product.ocasion = type.motivo ? type.motivo : "No incluido";
    product.para = type.para ? type.para : "No incluido";
    product.texto_globo = type.text ? type.text : "No incluido";
    product.numero_arreglo = type.number ? type.number : "No incluido";
    product.adicionales = adicionales.items;
    let totalAdicionales = adicionales.total;
    if (product.cantidad < 1) {
      console.log("ya no quedan mas productos");
      return;
    }
    let index = this.cart.findIndex((pr: any) => pr.id === product.id);
    if (index < 0) {
      this.cart.push(product);
      this.cart_details.total =
        Number(product.precio) +
        Number(totalAdicionales) -
        Number(product.descuento);
      this.cart_details.precio_unitario = Number(product.precio);
      this.adicionales = totalAdicionales;
      window.localStorage.setItem("cart", JSON.stringify(this.cart));
      console.log(this.cart_details.total);
      return;
    }
    if (this.cart[index].count + 1 > product.cantidad) {
      console.log("ya no quedan mas productos");
      return;
    }
    this.cart[index].count = 1;
    this.cart_details.total =
      Number(product.precio) +
      Number(totalAdicionales) -
      Number(product.descuento);
    this.cart_details.precio_unitario = Number(product.precio);
    this.adicionales = totalAdicionales;
    window.localStorage.setItem("cart", JSON.stringify(this.cart));
    console.log(this.cart);
    console.log(this.cart_details);
  }

  setDataId(type?: string) {
    switch (type) {
      case "info":
        this.info = true;
        break;
      case "shipping":
        this.shipping = true;
        break;
      case "payment":
        this.payment = true;
        break;
      default:
        break;
    }
  }
}
