import { Component, OnInit } from '@angular/core';
import { ArreglosService } from 'src/app/services/arreglos.service';
import { Router } from '@angular/router';
import { ArregloscompraService } from 'src/app/services/arregloscompra.service';

@Component({
  selector: 'app-second-section-inicio',
  templateUrl: './second-section-inicio.component.html',
  styleUrls: ['./second-section-inicio.component.scss']
})
export class SecondSectionInicioComponent implements OnInit {


  public ocaciones;

  constructor(private arreglosService: ArregloscompraService, private router: Router) { }

  ngOnInit() {
    this.arreglosService.obtenerOcasiones().subscribe(data => {
      this.ocaciones = data;
    });
  }


  changePage(e){
    this.router.navigate(['/arreglos'],{state: {ocasion: e}});
  }

}
