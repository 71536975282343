import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'length',
  pure: false
})
export class LengthPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const length = value.length;
    return length;
  }

}
