import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ArreglosService } from 'src/app/services/arreglos.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})

export class ContactoComponent {
  
  public redesSocial: Observable<any>;

  constructor(private arreglosService: ArreglosService) { 
    this.redesSocial = arreglosService.obtenerRedesSOciales();
  }
}
