import { Component, OnInit } from '@angular/core';
import { ArreglosService } from 'src/app/services/arreglos.service';
import { ActivatedRoute, Router } from '@angular/router'; 

@Component({
  selector: 'app-arreglos-arreglos-section',
  templateUrl: './arreglos-section-arreglos.component.html',
  styleUrls: ['./arreglos-section-arreglos.component.scss']
})
export class ArreglosSectionArreglosComponent implements OnInit {

  select: any = '';

  public filtros = [
    {
      name: 'Ocasión',
      list: [],
      open: false,
      select: this.select
    },
    {
      name: 'Tamaño',
      list: [],
      open: false,
      select: this.select
    },/* 
    {
      name: 'Precio',
      list: [
        {name : "$0   - $99 ", min: 0  , max: 99 },
        {name : "$99  - $199", min: 99 , max: 199},
        {name : "$200 - $299", min: 200, max: 299},
        {name : "$300 - $399", min: 300, max: 399},
        {name : "$400 - $499", min: 400, max: 499},
        {name : "$500 - $599", min: 500, max: 599}
      ],
      open: false,
      select: ''
    }, */
    {
      name: 'Tipo',
      list: [],
      open: false,
      select: this.select
    }
  ];

  public precio = {
    min: 0,
    max: 1600,
    open: false
  }

  public mostrarDiv: boolean = false;

  constructor(private arreglosService: ArreglosService, public router: Router) { 
    if(router.getCurrentNavigation().extras.state){
      console.log(router.getCurrentNavigation().extras.state.ocasion);
      this.filtros[0].select = {name:router.getCurrentNavigation().extras.state.ocasion};
    }
  }

  ngOnInit() {
    if (this.router.url === '/arreglos') {
      this.mostrarDiv = true;
    } else {
      this.mostrarDiv = false;
    }

    this.arreglosService.obtenerOcasiones().subscribe(data => {
      this.filtros[0].list = data;
    });
    this.arreglosService.obtenerTamanos().subscribe(data => {
      this.filtros[1].list = data;
    });
    this.arreglosService.obtenerTipos().subscribe(data => {
      this.filtros[2].list = data;
    });
  }

  getAllArreglos(){
    this.arreglosService.getAllArreglos();
  }

}
