import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PromocionesService {

  promociones = [];
  time: Date;

  constructor(private db: AngularFirestore) {
    this.descargarPromocion();
  }

  descargarPromocion() {
    this.db.collection('promociones').ref.where('estatus', '==', 'activado').get().then(data => {
      data.docs.forEach(data => {
        const promocion = data.data();
        const fecha = new Date(promocion.fechaExpiracion.seconds * 1000);
        fecha.setHours(23,59,59);
        const fechaActual = new Date();
        promocion.fechaExpiracion = fecha;
        this.time = fecha;
        if (fecha < fechaActual) {
          this.promociones = [];
        } else {
          this.promociones.push(promocion);
        }
      });
    });
  }

  obtenerPromociones() {
    return of(this.promociones);
  }

  obtenerTime(){
    return this.time;
  }

}