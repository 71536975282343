import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';
import { OrdenescompraService } from 'src/app/services/ordenescompra.service';

@Component({
  selector: 'app-estado-orden',
  templateUrl: './estado-orden.component.html',
  styleUrls: ['./estado-orden.component.scss']
})
export class EstadoOrdenComponent implements OnInit {

  public data: any;

  constructor(
    public _data: DataService,
    public router: Router,
    private _activeRouter: ActivatedRoute,
    private _general: GeneralService,
    private ordenesServie: OrdenescompraService, 
  ) {}

  public ngOnInit() {
    this._general._spinner.show();
    this._activeRouter.params.subscribe((params) => { 
      this.initData(params.id);
    });
  }

  public async initData(value: any): Promise<any>  { 
    try {
      let response: any = await this.ordenesServie.getOrder(value);   
      this.data = response;
    } catch (error) {
      console.log(error);
    } 
    console.log(this.data);
    this._general._spinner.hide();
  }

  format_date(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  }

  public getTotal(value){
    let total: Number;
    return total = value.precio - value.descuento + this.data.deliveryPrice
  }

}
