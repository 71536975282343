import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-section-event',
  templateUrl: './second-section-event.component.html',
  styleUrls: ['./second-section-event.component.scss']
})
export class SecondSectionEventComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
