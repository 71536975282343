import { Component, OnInit } from '@angular/core';
import { Card } from './models/Card';
import { StripeService } from './services/stripe.service';

@Component({
  selector: 'stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {
  public card: Card = new Card('', '', false, null);

  constructor(private _stripe: StripeService) {}

  ngOnInit(): void {}

  public addCard(ev: Card): void {
    this.card = ev;
  }

  public async addPaymentMethod(): Promise<void> {
    try {
      let res = await this._stripe.addPaymentMethod(
        this.card,
        'cus_LE5faxUmsFw0Cs'
      );
      console.log('res: ', res);
    } catch (error) {
      console.log(error);
    }
  }

  public async updatePaymentMethod(): Promise<void> {
    try {
      let res = await this._stripe.updatePaymentMethod(
        this.card,
        'pm_1KWteRBAKpAhq5PM6ALBW2lg'
      );
      console.log('res: ', res);
    } catch (error) {
      console.log(error);
    }
  }

  public async deletePaymentMethod(): Promise<void> {
    try {
      await this._stripe.deletePaymentMethod('pm_1KWteRBAKpAhq5PM6ALBW2lg');
      console.log('Tarjeta eliminada');
    } catch (error) {
      console.log(error);
    }
  }

  public async attachPaymentMethod(): Promise<void> {
    try {
      await this._stripe.attachPaymentMethod(
        'pm_1KWteRBAKpAhq5PM6ALBW2lg',
        'cus_LE5faxUmsFw0Cs'
      );
      console.log('Tarjeta linkeada');
    } catch (error) {
      console.log(error);
    }
  }

  public async paymentWithSavedPaymentMethod(): Promise<void> {
    try {
      let res = await this._stripe.paymentWithSavedPaymentMethod(
        'card_1KXdu7JfeOFKWaqZ3be4R5vt',
        20000,
        'MXN',
        'cus_LE5faxUmsFw0Cs'
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public async payment(): Promise<void> {
    try {
      let res = await this._stripe.payment(
        20000,
        'MXN',
        'cus_LE5faxUmsFw0Cs',
        this.card
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public async paymentAndSavePaymentMethod(): Promise<void> {
    try {
      let paymentMethod: any = await this._stripe.addPaymentMethod(
        this.card,
        'cus_LE5faxUmsFw0Cs'
      );
      let res = await this._stripe.paymentWithSavedPaymentMethod(
        paymentMethod.id,
        20000,
        'MXN',
        'cus_LE5faxUmsFw0Cs'
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public async subscribeWithSavedPaymentMethod(): Promise<void> {
    try {
      let res = await this._stripe.subscribe(
        'pm_1KX5aRBAKpAhq5PMxf1wGnyG',
        'cus_LE5faxUmsFw0Cs',
        'price_1Jy49ZBAKpAhq5PMg7QaRyow'
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public async subscribe(): Promise<void> {
    try {
      let paymentMethod: any = await this._stripe.addPaymentMethod(
        this.card,
        'cus_LE5faxUmsFw0Cs'
      );
      let res = await this._stripe.subscribe(
        paymentMethod.id,
        'cus_LE5faxUmsFw0Cs',
        'price_1Jy4jABAKpAhq5PMPFDD1kDO'
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public async createCustomer(): Promise<void> {
    try {
      let res: any = await this._stripe.createCustomer(
        'ieddaguilar@gmail.com',
        'Eduardo Aguilar',
        '8126665662'
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  public async cancelSubscription(): Promise<void> {
    try {
      let res: any = await this._stripe.cancelSubscription(
        'sub_1KX9HeBAKpAhq5PMvEpf3Z1n'
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }
}
