import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventos',
  pure: false
})
export class EventosPipe implements PipeTransform {

  transform(value: Array<any>, ...args: any[]): any {
    let decoraciones = []
    value.forEach(element => {
      element.categoria.forEach(cat => {
        if(cat === args[0]){
          decoraciones.push(element);
        }
      });
    });
    return decoraciones;
  }

}
