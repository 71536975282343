import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro',
  pure: false
})
export class FiltroPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let arreglos = value;
    arreglos = arreglos.filter( arreglo => arreglo.activo);
    if(args[4]){
      arreglos = this.favorito(arreglos);
    }
    if(args[5]){
      arreglos = this.popularidad(arreglos, args);
    }
    if(!args[4] && !args[5]){
      arreglos = this.filtros(arreglos, args);
    }
    return arreglos;
  }


  favorito(data){
    const arreglos = [];
    data.forEach(arreglo => {
      if (arreglo.favorito && arreglos.length < 6) {
        arreglos.push(arreglo);
      }
    });
    if (arreglos.length < 6 && data.length > 0) {
      data.forEach(arreglo => {
        if (!arreglo.favorito  && arreglos.length < 6) {
          arreglos.push(arreglo);
        }
      });
    }
    return arreglos;
  }

  popularidad(data, args) {
    const arreglos = [];
    data = this.orderGamesData(data);
    data.forEach(arreglo => {
      if (arreglo.tamano.name === args[2].name && arreglos.length < 3 && arreglo.id !== args[6]) {
        arreglos.push(arreglo);
      }
    });
    if (arreglos.length < 3 && data.length > 0) {
      data.forEach(arreglo => {
        if (arreglo.tamano.name !== args[2].name  && arreglos.length < 3) {
          arreglos.push(arreglo);
        }
      });
    }
    return arreglos;
  }


  orderGamesData(data) {
    data.sort((a, b) => {
      return b.sumaPopularidad - a.sumaPopularidad;
    });
    return data;
  }

  filtros(value, args){
    let arreglos = value;
    if(args[0] !== '') {
      arreglos = arreglos.filter(arreglo => {
        let retornar = false;
        arreglo.ocasion.forEach(data => {
          if(data.name === args[0].name){
            retornar = true;
          }
        })
        return retornar;
      });
    }
    if(args[1] !== '') {
      arreglos = arreglos.filter(arreglo => arreglo.tipo.name === args[1].name);
    }
    if(args[2] !== '') {
      arreglos = arreglos.filter(arreglo => arreglo.tamano.name === args[2].name);
    }
    if(args[3] !== '') {
      arreglos = arreglos.filter(arreglo => {
        let retornar = false;
        if(Number(arreglo.precio) <= args[3].max){
          retornar = true;
        }
        return retornar;
      });
    }
    return arreglos;  
  }

}
