import { Component, OnInit, Input } from '@angular/core';
import { ArreglosService } from 'src/app/services/arreglos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PromocionesService } from 'src/app/services/promociones.service';
import { Observable } from 'rxjs';
import { ArregloscompraService } from 'src/app/services/arregloscompra.service';

@Component({
  selector: 'app-arreglos-section',
  templateUrl: './arreglos-section.component.html',
  styleUrls: ['./arreglos-section.component.scss']
})
export class ArreglosSectionComponent implements OnInit {

  public ocasion = '';
  public tipo = '';
  public tamano = '';
  public precio = '';
  public favorito = false;
  public popularidad = false;
  public id = '';
  public showButton = true;
  public showPrecio = true;

  @Input()
  set filtroOcasion(value) {
    this.ocasion = value;
  }
  @Input()
  set filtroTipo(value) {
    this.tipo = value;
  }
  @Input()
  set filtroTamano(value) {
    this.tamano = value;
  }
  @Input()  
  set filtroPrecio(value) {
    this.precio = value;
  }
  @Input()  
  set filtroFavorito(value: boolean) {
    this.favorito = value;
  }
  @Input()  
  set filtroPopularidad(value: boolean) {
    this.popularidad = value;
  }
  @Input()  
  set filtroid(value: string) {
    this.id = value;
  }

  public arreglos: Observable<any>;
  
  constructor(public arreglosService: ArregloscompraService, 
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.arreglos = this.arreglosService.obtenerArreglos();
    this.route.url.subscribe(data => {
      if(data[0].path !== 'inicio'){
        this.showButton = false;
        this.showPrecio = false;
      }  
      if(data[0].path === 'arreglos'){ 
        this.showPrecio = true;
      }  
      
    }); 
  }

  order(id){
    this.router.navigate(['/arreglo', id]);
  }

  getMoreDocuments(){
    this.arreglosService.getNextPagination();
  }

}
