import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ArregloscompraService } from "src/app/services/arregloscompra.service";
import { DataService } from "src/app/services/data.service";
import { GeneralService } from "src/app/services/general.service";
import { OrdenescompraService } from "src/app/services/ordenescompra.service";
import { ModalExtrasComponent } from "../modal-extras/modal-extras.component";

@Component({
  selector: "app-first-section-ordenar-compra",
  templateUrl: "./first-section-ordenar-compra.component.html",
  styleUrls: ["./first-section-ordenar-compra.component.scss"],
})
export class FirstSectionOrdenarCompraComponent implements OnInit {
  private id;
  public arreglo: any = {
    id: "",
    nombre: "",
    ocasion: "",
    tipo: "",
    descripcion: "",
    precio: "0",
    imagen: "../../../../assets/img/sin-imagen.png",
    imagenes: ["../../../../assets/img/sin-imagen.png"],
    favorito: false,
    activo: true,
    tamano: "mediano",
    popularidad: {
      redSocial: {},
      orden: 0,
      pista: 0,
    },
  };
  public redesSociales: Observable<any>;
  public adicionales: Observable<any>;
  public adicionalesSeleccionados = [];
  public imagenSelected = "../../../../assets/img/sin-imagen.png";

  public formProspecto: FormGroup;

  public typeDelivery: string = "";

  public text = new FormControl("");
  public number = new FormControl("");

  constructor(
    private route: ActivatedRoute,
    private arreglosService: ArregloscompraService,
    private ordenesServie: OrdenescompraService,
    public data: DataService,
    private _general: GeneralService,
    private dialog: MatDialog
  ) { }

  ngOnInit() { 
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      this.getArreglo();
    });
    this.adicionales = this.arreglosService.obtenerAdicionales();
    this.redesSociales = this.arreglosService.obtenerRedesSOciales();
    this.createForm();
    this.data.sucursal = "";
    this.data.info = false;
    this.data.shipping = false;
    this.data.payment = false;
    this.formProspecto.reset();

    this.formProspecto.controls['motivo'].setValue('');
    this.formProspecto.controls['para'].setValue('');
    this.data.tipoEnvio = '';

/* 
    this.arreglosService.obtenerAdicionales().subscribe((data) => {
      this.data.adicionalesSeleccionados.forEach(adicional => {
        let get = data.find(item => item.id === adicional.id);
        if (get) { 
          get.selected = false;
          this.data.adicionalesSeleccionados.splice(get.selected, 1);
          this.data.adicionalesSeleccionados =
            this.data.adicionalesSeleccionados.filter(
              (adi) => adi !== get.id
            );
        }
      });
    }); */

  }



  createForm() {
    this.formProspecto = new FormGroup({
      motivo: new FormControl("", [Validators.required]),
      para: new FormControl("", [Validators.required]),
      text: new FormControl(""),
      number: new FormControl(""),
    });
  }

  getArreglo() {
    this.arreglosService.obtenerArreglo(this.id).subscribe((data) => {
      this.arreglo = data.data();
      this.arreglo.id = data.id;
      this.arreglo.imagenes.push(this.arreglo.imagen);
      this.imagenSelected = this.arreglo.imagen;
    });
  }

  guardarAdicional(data) {
    if (!data.selected) {
      data.selected = true;
      this.data.adicionalesSeleccionados.push({
        id: data.id,
        imagen: data.imagen,
        nombre: data.nombre,
        precio: data.precio,
      });
    } else {
      data.selected = false;
      this.data.adicionalesSeleccionados.splice(data.selected, 1);
      this.data.adicionalesSeleccionados =
        this.data.adicionalesSeleccionados.filter(
          (adicional) => adicional !== data.id
        );
    }
    console.log(this.data.adicionalesSeleccionados);
  }

  shared(data) {
    this.ordenesServie.compartir(this.arreglo, data);
    switch (data) {
      case "whatsapp":
        this.whatsapp();
        break;
      case "twitter":
        this.twitter();
        break;
      case "facebook":
        this.facebook();
        break;
    }
  }

  facebook() {
    const data = "https://www.facebook.com/sharer/sharer.php?u=";
    this.sharedPage(data);
  }

  twitter() {
    const data = "https://twitter.com/intent/tweet?text=";
    this.sharedPage(data);
  }

  whatsapp() {
    const data = "https://wa.me/?text=";
    this.sharedPage(data);
  }

  sharedPage(data) {
    alert(location.href);
    window.open(data + location.href);
  }

  addToCart(arreglo: any) {
    const { motivo, para } = this.formProspecto.value;
    let texto = this.text.value;
    let number = Number(this.number.value);

    const type: any = {
      motivo,
      para,
      text: texto,
      number: number,
    };
    const adicionalCount = this.data.adicionalesSeleccionados.reduce(
      (acumulador: any, item: any) => acumulador + Number(item.precio),
      0
    );
    const adicionales: any = {
      items: this.data.adicionalesSeleccionados,
      total: adicionalCount,
    };
    this.data.addProductToCart(arreglo, adicionales, type);
    this._general.openRouter("verificar");
  }


  public openModalExtras() {
    const dialogRef = this.dialog.open(ModalExtrasComponent, {
      width: "500px",
      height: "500px",
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
}
