import { Component, OnInit } from '@angular/core';
import { SucursalesService } from 'src/app/services/sucursales.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fourth-section-inicio',
  templateUrl: './fourth-section-inicio.component.html',
  styleUrls: ['./fourth-section-inicio.component.scss']
})
export class FourthSectionInicioComponent implements OnInit {

  public sucursales: Observable<any>;

  constructor(private sucursalesService: SucursalesService) { }

  ngOnInit() {
    this.sucursales = this.sucursalesService.obtener();
  }

}
