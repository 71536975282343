import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-four-section-event",
  templateUrl: "./four-section-event.component.html",
  styleUrls: ["./four-section-event.component.scss"],
})
export class FourSectionEventComponent implements OnInit {
  public clientes: Array<any> = [
    {
      id: 1,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image1.png",
    },
    {
      id: 2,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image2.png",
    },
    {
      id: 3,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image3.png",
    },
    {
      id: 4,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image4.png",
    },
    {
      id: 5,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image5.png",
    },
    {
      id: 6,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image6.png",
    },
    {
      id: 7,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image7.png",
    },
    {
      id: 8,
      url: "",
      image: "../../../../../assets/img/clientes_marcas/image8.png",
    },
  ];
  constructor() {}

  ngOnInit() {}
}
