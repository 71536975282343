import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ArreglosService } from 'src/app/services/arreglos.service';

@Component({
  selector: 'app-arreglos-personalizados-section',
  templateUrl: './arreglos-personalizados-section.component.html',
  styleUrls: ['./arreglos-personalizados-section.component.scss']
})
export class ArreglosPersonalizadosSectionComponent implements OnInit {

  public ocasion = '';
  public tipo = '';
  public tamano = '';
  public precio = '';
  public favorito = false;
  public popularidad = false;
  public id = '';
  public showButton = true;

  @Input()
  set filtroOcasion(value) {
    this.ocasion = value;
  }
  @Input()
  set filtroTipo(value) {
    this.tipo = value;
  }
  @Input()
  set filtroTamano(value) {
    this.tamano = value;
  }
  @Input()  
  set filtroPrecio(value) {
    this.precio = value;
  }
  @Input()  
  set filtroFavorito(value: boolean) {
    this.favorito = value;
  }
  @Input()  
  set filtroPopularidad(value: boolean) {
    this.popularidad = value;
  }
  @Input()  
  set filtroid(value: string) {
    this.id = value;
  }

  public arreglos: Observable<any>;
  
  constructor(public arreglosService: ArreglosService, 
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.arreglos = this.arreglosService.obtenerArreglos();
    this.route.url.subscribe(data => {
      if(data[0].path !== 'arreglos'){
        this.showButton = false;
      }
    });
  }

  order(id){
    this.router.navigate(['/ordenar', id]);
  }

  getMoreDocuments(){
    this.arreglosService.getNextPagination();
  }

  public shared(social: 'whatsapp', item:any): void { 
    const text = `Hola, Tulioos! Me interesa algo como el arreglo ${item.nombre} `;
    this.getshared(social, text);
  }

  getshared(
    social: 'whatsapp',
    text: string, 
  ) {
    console.log(text);  
    if (social === 'whatsapp') {
      this.sharedWhatsapp(text);
    }
  }

  sharedWhatsapp(text: string): void {
    this.open('https://wa.me/528112998266?text=' + text); 
  }
  public open(page: string, out?: boolean): void {
    if (!out) {
      window.open(page);
    } else {
      window.open(page, '_self');
    }
  }

}
