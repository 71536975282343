import { Component, OnInit } from '@angular/core';
import { ArreglosService } from 'src/app/services/arreglos.service';
import { Observable, VirtualTimeScheduler } from 'rxjs';
import { FacebookService } from 'src/app/services/facebook.service';

@Component({
  selector: 'app-fifth-section-inicio',
  templateUrl: './fifth-section-inicio.component.html',
  styleUrls: ['./fifth-section-inicio.component.scss']
})
export class FifthSectionInicioComponent implements OnInit {

  public redesSocial: Observable<any>;
  /* public posts = []; */

  constructor(private arreglosService: ArreglosService) { 
    this.redesSocial = arreglosService.obtenerRedesSOciales();
  }

  ngOnInit() {
    /* this.getPost(); */
  }

/* 

,private facebookService: FacebookService
  getPost(){
    this.facebookService.getPost().subscribe(data => {
      const posts = data.posts.data;
      posts.forEach(post => {
        this.facebookService.getImage(post.id).subscribe(data => {
          const newPost = {
            picture: data.full_picture,
            message: post.message
          }
          this.posts.push(newPost);
        });
      });
    })
  } */

}
