import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ProspectosService {



  constructor(private db: AngularFirestore) { }

  guardarProspecto(data) {
    this.db.collection('prospectos').add({
      nombre: data.nombre,
      email: data.email,
      telefono: data.telefono,
      empresa: data.empresa,
      mensaje: data.mensaje,
      fechaCreacion: new Date(),
      tipo: data.tipo
    }).finally(() => {
      Swal.fire({
        position: 'center',
        type: 'success',
        title: 'Correo enviado',
        showConfirmButton: false,
        timer: 1500
      });
    })
  }

}
