import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-third-section-inicio',
  templateUrl: './third-section-inicio.component.html',
  styleUrls: ['./third-section-inicio.component.scss']
})
export class ThirdSectionInicioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
