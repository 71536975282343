import { Card } from './Card';

export class Payment {
  public id: string;

  public amount: number;

  public created: number;

  public customer: string;

  public currency: string;

  public payment_method: string;

  public status: string;

  public client_secret: string;

  constructor(public payment: any) {
    this.id = payment.id;
    this.amount = payment.amount;
    this.created = payment.created;
    this.customer = payment.customer;
    this.currency = payment.currency;
    this.payment_method = payment.payment_method;
    this.status = payment.status;
    this.client_secret = payment.client_secret;
  }

  public get() {
    return {
      id: this.id,
      amount: this.amount,
      created: this.created,
      customer: this.customer,
      currency: this.currency,
      payment_method: this.payment_method,
      status: this.status,
      client_secret: this.client_secret,
    };
  }
}
