import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ArregloscompraService } from "src/app/services/arregloscompra.service";
import { DataService } from "src/app/services/data.service";
import { GeneralService } from "src/app/services/general.service";
import { OrdenescompraService } from "src/app/services/ordenescompra.service";
import { Card } from "src/app/stripe/models/Card";
import { StripeService } from "src/app/stripe/services/stripe.service";
declare var stripe: any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  public complete: number = 0;

  public cardType: string = "card";

  public card: any;

  public payment: any = new Object();

  public data: any = [];

  public temporalCart: any = [];

  public sendConfirmation: boolean = false;

  public cardStripe: Card = new Card("", "", false, null);

  public getconfirmation: boolean = false;

  public deliveryPrice = 0;

  public deliveryPriceDiscount = 0;

  public newProducto!: any[];

  public sucursal!: any;

  constructor(
    public _data: DataService,
    public _general: GeneralService,
    public ordenesService: OrdenescompraService,
    public arreglos: ArregloscompraService,
    public _stripe: StripeService,
    private db: AngularFirestore
  ) { }

  async ngOnInit(): Promise<void> { }

  public addCard(ev: Card): void {
    this.cardStripe = ev;
  }

  /* public getError(data?: any): string {
    let error = "";
    if(data){
      if (!data.createPaymentIntent.success) {
        error = "Error al procesar tu pago";
      }
      if (!data.confirmCardPayment.success) {
        error =
          "La tarjeta ingresada no es valida o ha sido declinada por el banco";
      }
      if (!data.confirmPaymentIntent.success) {
        error =
          "La tarjeta ingresada no es valida o ha sido declinada por el banco";
      }
    } 
    return error;
  } */

  public async send(): Promise<void> {
    this._general._spinner.show();
 
    if (this.getconfirmation == true) {

      if (this._data.sucursal){
        if(this._data.sucursal.url){
          this.sucursal = this._data.sucursal.url
        }else{
          this.sucursal = '';
        }
      }else{
        this.sucursal = '';
      }

      let numberArticles = 0;
      const payment: any = {
        numero_folio: 0,
        card_name: "",
        card_type: this.cardType,
        cart: this._data.cart,
        numberArticles: 1,
        total: Number(this._data.cart_details.precio_unitario),
        total_cost: Number(this._data.cart_details.total) + this._data.precioEnvio,
        discount: this._data.cart[0].descuento,
        payment_method: "stripe",
        estado: "Ordered",
        date: new Date(),
        deliveryPrice: this._data.precioEnvio ? this._data.precioEnvio : 0,
        deliveryPriceDiscount: 0,
        pickup: "",
        dateDelivery: null,
        guia: "",
        countryCode: null,
        ...this.payment,
        type: this._data.sucursal ? 'sucursal' : 'home',
        imgSucursal: this.sucursal,
        fecha_desde: this._data.fechaRecoleccion ? this._data.fechaRecoleccion : '',
        totalAdicionales: this._data.adicionales,
        numberAdicionales: this._data.cart[0].adicionales.length
      };
      let res: any = new Object();
      res = await this._stripe.payment(
        payment.total_cost * 100,
        "MXN",
        "",
        this.cardStripe,
        {
          nombre: this.payment.nombre,
          correo: this.payment.correo,
          telefono: this.payment.telefono,
        }
      );
      console.log(res);
      payment.card_name = this.cardStripe.name;
      /* const error = this.getError(res);  */
      if (res.status === "succeeded") {
        payment.payment_id = res.id;
        payment.quien_envia = payment.quien_envia ? payment.quien_envia : this.cardStripe.name
        this.successPayment(payment);
      } else {
        this._general._spinner.hide();
        /* this._general.alertError('Upps!', error); */
        this.sendConfirmation = false;
      }
    } else {
      this._general._spinner.hide();
      this.sendConfirmation = false;
    }
  }

  public async successPayment(payment: any): Promise<void> {
    let folio = await this.ordenesService.getOrdenFolio();
    payment.numero_folio = Number(folio ? folio : 0);
    /* this._data.cart.forEach((arreglo: any) => {
      let ref = this.getCollectionRef('arregloscompra').doc(arreglo.id);
      ref.update({
        activo: false,
      });
    }); */

    this.ordenesService.newOrden(payment).then(() => {
      this._general._spinner.hide();
      this._data.order = payment;
      this._data.cart = [];
      this._general.openRouter("exito");
    });
    return;
  }

  getCollectionRef(collection: any) {
    return this.db.collection(collection).ref;
  }

  nextCollapseFour() {
    this.getconfirmation = true;
    this.payment.card_name = this.cardStripe.name;
    this.data = this.payment;
    this._data.setDataId("payment");
  }

  public setDataInfo(values: any): void {
    this.payment.nombre = values.nombre;
    this.payment.correo = values.correo;
    this.payment.telefono = values.telefono;
  }

  public setDataAddress(values: any): void {
    this.payment.quien_recibe = values.quien_recibe ? values.quien_recibe : 'No incluido';
    this.payment.quien_envia = values.quien_envia ? values.quien_envia : 'No incluido';
    this.payment.mensaje = values.mensaje ? values.mensaje : 'No incluido';
    this.payment.postal = this._data.sucursal ? this._data.sucursal.cp : values.postal;
    this.payment.municipio = this._data.sucursal ? this._data.sucursal.municipio : values.municipio;
    this.payment.colonia = this._data.sucursal ? this._data.sucursal.colonia : values.colonia;
    this.payment.calle = this._data.sucursal ? this._data.sucursal.calle : values.calle;
    this.payment.indicaciones = this._data.sucursal ? '' : values.indicaciones;
    this.payment.dia_entrega = this._data.fechaRecoleccion;
    this.payment.horario_entrega = this._data.horaDeRecoleccion;
  }
}
