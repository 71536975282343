import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { GeneralService } from "src/app/services/general.service";
import { OrdenescompraService } from "src/app/services/ordenescompra.service";
import { SucursalesService } from "src/app/services/sucursales.service";
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: "app-checkout-shipping-address",
  templateUrl: "./checkout-shipping-address.component.html",
  styleUrls: ["./checkout-shipping-address.component.scss"],
})
export class CheckoutShippingAddressComponent implements OnInit {
  @Output() values: EventEmitter<any> = new EventEmitter();

  public complete: number = 0;

  public municipios: any[];

  public minute: Array<any> = ["00", "30"];

  public form: FormGroup;

  public formsucursal: FormGroup;

  public sucursales: Observable<any>;

  public selected: number = -1;

  public data: any[] = [];

  public postales: any[] = [];

  public colonias: any[] = [];

  public fecha_desde: FormControl = new FormControl("", Validators.required);

  public todayDate: Date = new Date();

  public desde: FormControl = new FormControl("", Validators.required);
  public desdeMinutos: FormControl = new FormControl("", Validators.required);

  public todayHour: Date = new Date();

  public newdate;
  public tope;
  public getHour;

  public message: string = '';


  public control = new FormControl();
  public filteredStreets: Observable<string[]>;


  constructor(
    public _data: DataService,
    public _general: GeneralService,
    public ordenesService: OrdenescompraService,
    public sucursalesService: SucursalesService
  ) { }

  ngOnInit(): void {
    this.setMatDatePicker();
    this.sucursales = this.sucursalesService.obtener();
    this.createFormSucursal();
    this.createFormHome();

    this.ordenesService.obtenerCps().subscribe((data) => {
      this.data = data;
      this.data.forEach((it) => {
        if (this.postales.indexOf(it.codigoPostal) == -1) this.postales.push(it.codigoPostal);
      });


      /* this.data.forEach((p) => {
        if (
          this.postales.findIndex(
            (pd) => pd.codigoPostal === p.codigoPostal
          ) === -1
        ) {
          this.postales.push(p);
        }
      }); */
    });
    this.fecha_desde.valueChanges.subscribe((data) => {

      this.newdate = data.toString().split(" ", 4)[2];
      this.tope = this.todayDate.toString().split(" ", 4)[2];
      let actualhora: Date = new Date();
      this.getHour = actualhora.toString().split(" ", 5)[4].slice(0, 2);

      let newdate = data.toString().split(" ", 4);
      this._data.fechaRecoleccion =
        newdate[2] +
        " " +
        "de" +
        " " +
        newdate[1] +
        " " +
        "de" +
        " " +
        newdate[3];
    });

    this.desde.valueChanges.subscribe((data) => {
      this._data.horaDeRecoleccion = this.desde.value + ':' + this.desdeMinutos.value;
      console.log(this._data.horaDeRecoleccion);
    })

    this.desdeMinutos.valueChanges.subscribe((data) => {
      this._data.horaDeRecoleccion = this.desde.value + ':' + this.desdeMinutos.value;
      console.log(this._data.horaDeRecoleccion);
    })

    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  public setMatDatePicker() {
    let maximaFechaInicio = new Date();
    let minimoFechaInicio = new Date(
      maximaFechaInicio.getFullYear(),
      maximaFechaInicio.getMonth(),
      maximaFechaInicio.getDate() + 1
    );
    this.todayDate = minimoFechaInicio;
  }

  createFormHome() {
    this.form = new FormGroup({
      quien_recibe: new FormControl("", Validators.required),
      quien_envia: new FormControl("", Validators.required),
      mensaje: new FormControl(""),
      postal: new FormControl(""),
      municipio: new FormControl("", Validators.required),
      colonia: new FormControl("", Validators.required),
      calle: new FormControl("", Validators.required),
      indicaciones: new FormControl(""),
      horario_entrega: new FormControl(""),
    });
  }

  createFormSucursal() {
    this.formsucursal = new FormGroup({
      quien_recibe: new FormControl("", Validators.required),
      quien_envia: new FormControl("", Validators.required),
      mensaje: new FormControl("", Validators.required),
    });
  }

  public selectedPostal(value: any) {
    let colonias = this.data.filter((it: any) => it.codigoPostal == value);
    this.colonias = colonias; 
    let muni = this.data.find((it: any) => it.codigoPostal == value);
    if(colonias){
      this.message = '';
    }
    if(muni){
      this.form.controls['municipio'].setValue(muni.municipio);
    } 
  }

  public selectedColonia(value: any) {  
    console.log(value); 
    let item = this.data.find((it: any) =>
      it.colonia == value.colonia && it.codigoPostal == value.codigoPostal
    );        
    this.form.controls['postal'].setValue(item.codigoPostal);
    if (item.precio == 0) {
      this.message = 'No contamos con entregas en esa área' 
      this._data.precioEnvio = Number(item.precio); 
      console.log(this._data.precioEnvio) 
    } else {
      this.message = ''
      if(item.precio===null){
        item.precio=0
      } 
      this._data.precioEnvio = Number(item.precio); 
      console.log(this._data.precioEnvio) 
    }   
  }

  public next() {
    if (this.form.valid) {
      this.values.emit(this.form.value);
    } else {
      this.complete = 0;
      this.form.markAllAsTouched();
      this.fecha_desde.markAllAsTouched();
      this.desde.markAllAsTouched();
      this.desdeMinutos.markAllAsTouched();
    }
  } 

  public send() {
    this._data.setDataId("shipping");
    this.values.emit(this.form.value);
    this.complete = 2;
  }

  public nextSucursal() {
    if (this.formsucursal.valid) {
      this.values.emit(this.formsucursal.value);
    } else {
      this.complete = 0;
      this.formsucursal.markAllAsTouched();
    }
  }

  public sendSucursal() {
    this._data.setDataId("shipping");
    this.values.emit(this.formsucursal.value);
    this.complete = 2;
  }

  public addSucursal(value: any) {
    this._data.sucursal = value;
    console.log(this._data.sucursal.url);
  }


  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }


  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.postales.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
