import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-section-arreglos-personalizados',
  templateUrl: './first-section-arreglos-personalizados.component.html',
  styleUrls: ['./first-section-arreglos-personalizados.component.scss']
})
export class FirstSectionArreglosPersonalizadosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
