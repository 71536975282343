import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PromocionesService } from 'src/app/services/promociones.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-promocion-section',
  templateUrl: './promocion-section.component.html',
  styleUrls: ['./promocion-section.component.scss']
})
export class PromocionSectionComponent implements OnInit {

  time: string;

  public promociones: Observable<any>;
  @ViewChild('target', { static: false }) MyProp: ElementRef;

  constructor(private promocionesService: PromocionesService) { }

  ngOnInit() {
    this.promociones = this.promocionesService.obtenerPromociones();
    setInterval(() => {
      const time =  moment(this.promocionesService.obtenerTime()).format('YYYY-MM-DD HH:mm:ss');
      const now = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
      const fecha1 = moment(now , "YYYY-MM-DD HH:mm:ss");
      const fecha2 = moment(time , "YYYY-MM-DD HH:mm:ss");
      let total = fecha2.diff(fecha1, 's');
      const dias = Math.floor(((total / 24)/60)/60);
      const diasToSeconds = (dias * 60 * 60 * 24);
      const horas = Math.floor(((total - diasToSeconds)/60)/60);
      const horasToSeconds = (horas * 60 * 60);
      const minutos = Math.floor((total - diasToSeconds - horasToSeconds)/60);
      const minutosToSeconds = (minutos * 60);
      const segundos = Math.floor(total - diasToSeconds - horasToSeconds - minutosToSeconds);
      this.time = dias + ' dias ' + horas + ':' + minutos + ':' + segundos;
    },1000 );
  }

  scrollDown() {
    this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

}