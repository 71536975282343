import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-first-section-event',
  templateUrl: './first-section-event.component.html',
  styleUrls: ['./first-section-event.component.scss']
})
export class FirstSectionEventComponent implements OnInit { 

  constructor() { }

  ngOnInit() { 
  } 
}
