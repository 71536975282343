import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrdenesService } from 'src/app/services/ordenes.service';

@Component({
  selector: 'app-modal-pista',
  templateUrl: './modal-pista.component.html',
  styleUrls: ['./modal-pista.component.scss']
})
export class ModalPistaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalPistaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ordenesService: OrdenesService) {
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public newPista: FormGroup;

  ngOnInit() {
    this.newPista = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      nombreDestinario: new FormControl('', [Validators.required]),
      emailDestinario: new FormControl('', [Validators.required, Validators.email])
    });
  }


  enviar() {
    const { nombre, email, nombreDestinario, emailDestinario} = this.newPista.value;
    const url = '';
    const newPista = {
      nombre: nombre,
      email: email,
      nombreDestinario: nombreDestinario,
      emailDestinario: emailDestinario,
      url: url,
      arreglo: this.data.arreglo
    }
    this.ordenesService.newPista(newPista);
    this.dialogRef.close(newPista);
  }

  cancelar() {
    this.dialogRef.close();
  }

}
