import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { DataService } from './services/data.service';
import { GeneralService } from './services/general.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private _general: GeneralService, private _data: DataService) {}

  public url : any;

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> { 
    if (this.url) {
      this.url = next.url[0].path; 
    }
    if (this.url === 'verificar' && this._data.cart.length < 1) {
      this._general.openRouter('inicio');
      return false;
    }
   /*  if(this.url != 'arreglo' || this.url != 'verificar'){ 
      this._data.adicionalesSeleccionados = [];
    } */
    return true;
  }
}
