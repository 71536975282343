import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sixth-section-sucursales',
  templateUrl: './sixth-section-sucursales.component.html',
  styleUrls: ['./sixth-section-sucursales.component.scss']
})
export class SixthSectionSucursalesComponent implements OnInit {

  public select = 'sociales-tab';
  public eventos = [
    {
      name: 'sociales-tab',
      href: '#sociales',
      titulo: 'Sociales',
      evento: 'sociales',
      descripcion: 'Cumpleaños, aniversarios, fiestas de graduación y reuniones familiares, contamos con muchas opciones para decorar tu fiesta! ',
      imagenes: [1, 1, 1, 1],
      img: '../../../../../assets/img/5.jpg'
    },
    {
      name: 'empresariales-tab',
      href: '#empresariales',
      titulo: 'Empresariales',
      evento: 'empresariales',
      descripcion: 'Reuniones, posadas, aniversarios y fiestas para empresas en interior o en exterior, podemos decorar tu lugar de trabajo para la ocasión!',
      imagenes: [1, 1, 1, 1],
      img: '../../../../../assets/img/3.jpg'
    },
    {
      name: 'industriales-tab',
      href: '#industriales',
      titulo: 'Industriales',
      evento: 'industriales',
      descripcion: 'Carreras masivas, eventos multitudinarios o conciertos, dale un toque a tu evento para que todos lo recuerden por su excelente imagen',
      imagenes: [1, 1, 1, 1],
      img: '../../../../../assets/img/7.jpg'
    },
    {
      name: 'populares-tab',
      href: '#populares',
      titulo: 'Los mas populares',
      evento: 'populares',
      descripcion: 'Nuestros diseños más pedidos, conoce lo que está de moda para tu próximo evento!',
      imagenes: [1, 1, 1, 1],
      img: '../../../../../assets/img/6.jpg'
    }
  ]


  constructor() { }

  ngOnInit() {
  }

}
