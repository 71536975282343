import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DecoracionesService {

  data = [];

  constructor(private db: AngularFirestore) {
    this.descargar();
  }

  descargar() {
    this.db.collection('decoraciones').ref.where('activo', '==', true).get().then(data => {
      data.docs.forEach(col => {
        const doc = col.data();
        this.data.push(doc);
      });
    });
  }

  obtener() {
    return of(this.data);
  }

}
