import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner'; 
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';  
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(
    public _spinner: NgxSpinnerService,
    public _http: HttpClient,
    public _router: Router
  ) {}
  
   public swalTimer( 
    title: any,
    text: string,
    timer: number,
    showConfirmButton: boolean,
    showCancelButton: boolean
  ) {
    return Swal.fire({ 
      title: title,
      text: text,
      showConfirmButton: showConfirmButton,
      confirmButtonColor: '#79a186',
      showCancelButton: showCancelButton,
      confirmButtonText: 'Acept',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      timer: timer,
    });
  }

  public getJsonData(route: any): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('../../assets/' + route + '.json').subscribe((data) => {
        resolve(data);
      });
    });
  }

  public openRouter(router: any): void {
    this._router.navigate([router]);
  }

  public alertSuccess(title: any, text: any) {
    return this.swalTimer('center', 'success', title, text, true);
  }

  public alertQuestion(title: any, text: any) {
    return this.swalTimer('center', 'question', title, text, true);
  }

  public alertError(title: any, text: any) {
    return this.swalTimer('center', 'error', title, text, true);
  }

  public alertWarning(title: any, text: any) {
    return this.swalTimer('center', 'warning', title, text, true);
  }
 
}
