import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-checkout-succes',
  templateUrl: './checkout-succes.component.html',
  styleUrls: ['./checkout-succes.component.scss']
})
export class CheckoutSuccesComponent implements OnInit {

  constructor(
    private _general : GeneralService
  ) { }

  ngOnInit() {
    this._general._spinner.hide();
  }

}
