import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ArreglosService } from "src/app/services/arreglos.service";
import { Observable } from "rxjs";
import { PromocionesService } from "src/app/services/promociones.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
})
export class InicioComponent implements OnInit {
  public arreglos: Observable<any>;
  public promociones = [];
  public ocaciones;

  public select = "sociales";

  constructor( 
    private router: Router, 
    private _data: DataService
  ) {}

  ngOnInit() { 
   console.log(this._data.cart);  
  }

  order(id) {
    this.router.navigate(["/ordenar", id]);
  }
}
