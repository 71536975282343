import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SucursalesService {

  data = [];

  constructor(private db: AngularFirestore) {
    this.descargar();
  }

  descargar() {
    this.db.collection('sucursales').ref.where('activo', '==', true).get().then(data => {
      data.docs.forEach(col => {
        const doc = col.data();
          this.data.push(doc);
      });
    });
  }

  obtener() {
    return of(this.data);
  }

}
