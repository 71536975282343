import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { PromocionesService } from "./promociones.service";
import { ArregloscompraService } from "./arregloscompra.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { of } from "rxjs";
import { DataService } from "./data.service";
import * as firebase from "firebase";

@Injectable({
  providedIn: "root",
})
export class OrdenescompraService {
  ordenes = [];

  public orders!: any[];

  constructor(
    private db: AngularFirestore,
    private arreglosService: ArregloscompraService,
    private _data: DataService,
    private http: HttpClient,
  ) {}

  newOrden(data) {
    return this.db
      .collection("ordenescompra")
      .add(data);
  }

  public async getOrdenFolio(): Promise<number> {
    let folio: number = 1000;
    const data = this.db
      .collection("ordenescompra")
      .ref.orderBy("numero_folio", "desc")
      .limit(1);
    let collection = await data.get();
    if (!collection.empty) {
      let listOrders = collection.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(listOrders);
      folio = Number(listOrders[0].numero_folio) + 1;
    }
    return folio;
  }

  compartir(data, social) {
    const index = data.popularidad.redSocial.findIndex(
      (i) => i.name === social
    );
    if (index >= 0) {
      data.popularidad.redSocial[index].total++;
    } else {
      const redSocial = {
        name: social,
        total: 1,
      };
      data.popularidad.redSocial.push(redSocial);
    }
    data.popularidad.shared++;
    this.arreglosService.actualizarArreglo(data);
  }

  obtenerOrdenes() {
    return of(this.ordenes);
  }

  public async getOrder(folio: any): Promise<any> {
    folio = Number(folio);
    try {
      const ref = this.getCollectionRef("ordenescompra");
      let query = ref.where("numero_folio", "==", folio);
      let collection = await query.get();
      let doc = null;
      if (collection.empty) {
        query = ref.where("guia", "==", folio);
        collection = await query.get();
        if (collection.empty) {
          return null;
        } else {
          doc = collection.docs[0];
        }
      } else {
        doc = collection.docs[0];
      }
      let data: any = doc.data();
      data.id = doc.id;
      if (!data.guia) {
        return data;
      }
      return data;
    } catch (error) {
      return [];
    }
  }

  getCollectionRef(collection: any) {
    return this.db.collection(collection).ref;
  }

  generateData(ref: any, data: any): Promise<any[]> {
    return new Promise((resolve) => {
      ref.onSnapshot((collection: any) => {
        collection.docChanges().forEach((document: any) => {
          const doc = document.doc.data();
          doc.id = document.doc.id;
          switch (document.type) {
            case "added":
              data.push(doc);
              break;
            case "modified":
              const modified = data.findIndex((dd: any) => dd.id === doc.id);
              if (modified >= 0) {
                data[modified] = doc;
              }
              break;
            case "removed":
              const removed = data.findIndex((dd: any) => dd.id === doc.id);
              if (removed >= 0) {
                data.splice(removed, 1);
              }
              break;
            default:
              break;
          }
        });
        resolve(data);
      });
    });
  }

  obtenerCps(){
    return this.http.get<Array<any>>('../assets/datas/CPsNuevoLeon.json');
  }

}
