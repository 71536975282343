import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Card } from '../models/Card';

@Component({
  selector: 'stripe-element-updatecard',
  templateUrl: './stripe-element-updatecard.component.html',
  styleUrls: ['./stripe-element-updatecard.component.scss'],
})
export class StripeElementUpdatecardComponent implements OnInit {
  @Output() change: EventEmitter<Card> = new EventEmitter();

  public form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    month: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
    ]),
    year: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
    ]),
  });

  public error: string = '';

  constructor() {}

  public ngOnInit(): void {
    this.form.valueChanges.subscribe((ev: any) => this.verifyCard(ev));
  }

  private verifyCard(ev: any): void {
    let valid = this.form.valid;
    if (ev.year && ev.month && this.form.valid) {
      let date = new Date(`${ev.year}/${Number(ev.month)}`);
      let today = new Date();
      if (date < today) {
        this.error = 'La tarjeta esta vencida';
        valid = false;
      } else {
        this.error = '';
      }
    }
    const card: Card = new Card(ev.name, this.error, valid, {
      exp_month: ev.month,
      exp_year: ev.year,
    });
    this.change.emit(card);
  }
}
