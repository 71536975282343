import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Card } from '../models/Card';
import { StripeService } from '../services/stripe.service';

@Component({
  selector: 'stripe-element-card',
  templateUrl: './stripe-element-card.component.html',
  styleUrls: ['./stripe-element-card.component.scss'],
})
export class StripeElementCardComponent implements OnInit {
  @Output() change: EventEmitter<Card> = new EventEmitter();

  private card: any;

  public error: string = '';

  public form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor(private _stripe: StripeService) {}

  public ngOnInit(): void {
    this.createCard();
    this.form.valueChanges.subscribe((ev: any) => this.verifyCard(ev));
  }

  private createCard(): void {
    const elements = this._stripe.stripe.elements();
    this.card = elements.create('card');
    this.card.mount('#card');
    this.card.on('change', (ev: any) => this.verifyCard(ev));
  }

  private verifyCard(ev: any): void {
    if(ev.error){
      if (ev.error.message) {
        this.error = ev.error.message 
      } else {
        this.error = ev.error.message = ''
      } 
    } 
    const card: Card = new Card(
      this.form.value.name,
      this.error,
      ev.complete,
      this.card
    );
    this.change.emit(card);
  }
}
