import { Component, OnInit } from '@angular/core';
import { DecoracionesService } from 'src/app/services/decoraciones.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-eventos-section',
  templateUrl: './eventos-section.component.html',
  styleUrls: ['./eventos-section.component.scss']
})
export class EventosSectionComponent implements OnInit {

  public select = 'sociales-tab';
  public eventos = [
    {
      name: 'sociales-tab',
      href: '#sociales',
      titulo: 'Sociales',
      evento: 'Sociales',
      descripcion: 'Tu fiesta se merece la mejor decoración! Contamos con decoraciones especiales para toda ocasión, no importa si tu festejo es chico o grande, nosotros tenemos algo para decorarlo',
      imagenes: [1,1,1,1]
    },
    {
      name: 'empresariales-tab',
      href: '#empresariales',
      titulo: 'Empresariales',
      evento: 'Empresariales',
      descripcion: '¿Tu empresa celebra un logro, un aniversario o un evento para los trabajadores? Dale la imagen perfecta con una decoración con globos!',
      imagenes: [1,1,1,1]

    },
    {
      name: 'industriales-tab',
      href: '#industriales',
      titulo: 'Eventos Masivos',
      evento: 'Industriales',
      descripcion: '¿Necesitas una decoración grande para un evento multitudinario? Cotízalo con nosotros! Tenemos experiencia en carreras, fiestas desfiles y más, no dudes en pedir informes!',
      imagenes: [1,1,1,1]
      ,
    },
    {
      name: 'populares-tab',
      href: '#populares',
      titulo: 'Más populares',
      evento: 'Populares',
      descripcion: 'Nuestras decoraciones más pedidas, para darte una idea para tu evento!',
      imagenes: [1,1,1,1]
    }
  ]

  public decoraciones: Observable<any>;


  constructor(private decoracionesService: DecoracionesService) { }

  ngOnInit() {
    this.decoraciones = this.decoracionesService.obtener();
  }
  

}
