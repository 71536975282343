import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.scss']
})
export class Section1Component implements OnInit {

  constructor(
    public _data: DataService,
    public _general: GeneralService
  ) { }

  ngOnInit() {
  }

}
