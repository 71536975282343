import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { NgxSpinnerService } from "ngx-spinner";
import { of } from "rxjs";
import { PromocionesService } from "./promociones.service";
import Swal from "sweetalert2";
import { ArreglosService } from "./arreglos.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class OrdenesService {
  ordenes = [];

  constructor(
    private db: AngularFirestore,
    private spinner: NgxSpinnerService,
    private promocionesService: PromocionesService,
    private arreglosService: ArreglosService,
    private http: HttpClient
  ) {}

  newOrden(data, arreglo) {
    /* this.spinner.show(); */
    return this.db
      .collection("ordenes")
      .add(data)
      .finally(() => {
        this.ordenes.push(data);
        if (this.promocionesService.promociones.length >= 0) {
          this.promocionesService.promociones[0].notificar = false;
        }
        this.spinner.hide();
        arreglo.popularidad.orden++;
        this.arreglosService.actualizarArreglo(arreglo);
      });
  }

  newPista(data) {
    this.spinner.show();
    const url = "http://localhost:4200/ordenar/" + data.arreglo.id;
    const pista = {
      fechaCreacion: new Date(),
      nombre: data.nombre,
      email: data.email,
      nombreDestinario: data.nombreDestinario,
      emailDestinario: data.emailDestinario,
      url: url,
      arreglo: data.arreglo.id
    };
    this.postPista(pista);
    this.db
      .collection("pistas")
      .add(pista)
      .finally(() => {
        this.spinner.hide();
        Swal.fire({
          position: "center",
          type: "success",
          title: "Pista enviada",
          showConfirmButton: false,
          timer: 1500
        });
        data.arreglo.popularidad.pista++;
        this.arreglosService.actualizarArreglo(data.arreglo);
      });
  }

  obtenerOrdenes() {
    return of(this.ordenes);
  }

  compartir(data, social) {
    const index = data.popularidad.redSocial.findIndex(i => i.name === social);
    if (index >= 0) {
      data.popularidad.redSocial[index].total++;
    } else {
      const redSocial = {
        name: social,
        total: 1
      };
      data.popularidad.redSocial.push(redSocial);
    }
    data.popularidad.shared++;
    this.arreglosService.actualizarArreglo(data);
  }

  postPista(e) {
/*     console.log('entro')
      const url = 'https://api.mailjet.com/v3.1/send';
      const body = {
        Messages:[
          {
            From: {
              Email: "tulioos.servicios@gmail.com",
              Name: "Tulioos"
            },
            To: [
              {
                Email: "ieddaguilar@gmail.com",
                Name: "Eduardo"
              }
            ],
            Subject: "Your email flight plan!",
            TextPart: "Dear passenger 1, welcome to Mailjet! May the delivery force be with you!",
            HTMLPart: "<h3>Dear passenger 1, welcome to <a href='https://www.mailjet.com/'>Mailjet</a>!</h3><br />May the delivery force be with you!"
          }
        ]
      }

      const headers = new HttpHeaders()
      .set('Content-Type', 'application/jsone')
      .set("e31b8c17a112c5c4d822a07c655dbef5", "923b11321eb938d2573cd32cfa3a16ca");

      this.http.post(url,body, { headers: headers }).subscribe(data => {
        console.log('Termino',data);
      }) */
  }
}
