import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { Card } from '../models/Card';
import { Customer } from '../models/Customer';
import { Payment } from '../models/Payment';
import { PaymentMethod } from '../models/PaymentMethod';
import { Subscription } from '../models/Subcription';
declare var require: any
declare var Stripe: any;
@Injectable({
  providedIn: 'root',
})
export class StripeService {
  public stripe: any;

  constructor(private _httpClient: HttpClient, public _functions: AngularFireFunctions,) {
    this.stripe = Stripe(environment.stripePK);
  }

  public async addPaymentMethod(card: Card, customer: string): Promise<Object> {
    let resSetupIntent = await this.postHttp('stripe/createSetupIntent', {
      customer,
    });
    let resConfirmCardSetup = await this.stripe.confirmCardSetup(
      resSetupIntent.client_secret,
      {
        payment_method: {
          card: card.element,
          billing_details: {
            name: card.name,
          },
        },
      }
    );
    let res = await this.postHttp(
      'stripe/retrievePaymentMethod',
      resConfirmCardSetup.setupIntent.payment_method
    );
    let paymentMethod: PaymentMethod = new PaymentMethod(res);
    return paymentMethod.get();
  }
  public async updatePaymentMethod(
    card: Card,
    payment_method: string
  ): Promise<Object> {
    const request = {
      payment_method,
      params: {
        billing_details: {
          name: card.name,
        },
        card: card.element,
      },
    };
    let res = await this.postHttp('stripe/updatePaymentMethod', request);
    console.log('res: ', res);
    let paymentMethod: PaymentMethod = new PaymentMethod(res);
    return paymentMethod.get();
  }
  public async deletePaymentMethod(payment_method: string): Promise<void> {
    await this.postHttp('stripe/deletePaymentMethod', payment_method);
    return;
  }
  public async attachPaymentMethod(
    payment_method: string,
    customer: string
  ): Promise<void> {
    const request = {
      payment_method,
      params: {
        customer,
      },
    };
    await this.postHttp('stripe/attachPaymentMethod', request);
    return;
  }
  public async createCustomer(
    email: string,
    name: string,
    phone: string
  ): Promise<Object> {
    const request = {
      email,
      name,
      phone,
    };
    let res = await this.postHttp('stripe/createCustomer', request);
    let customer: Customer = new Customer(res);
    return customer.get();
  }
  public async paymentWithSavedPaymentMethod(
    payment_method: string,
    amount: number,
    currency: string,
    customer: string
  ): Promise<Object> {
    let request = {
      payment_intent_id: '',
      payment_intent: {
        amount,
        currency,
        payment_method_types: ['card'],
        confirm: true,
        confirmation_method: 'manual',
        customer,
        payment_method,
      },
      next: 'create',
    };
    let res = await this.postHttp('stripe/payment', request);
    request.payment_intent_id = res.id;
    if (res.status === 'requires_action') {
      await this.stripe.handleCardAction(res.client_secret);
      request.next = 'confirm';
      res = await this.postHttp('stripe/payment', request);
    }
    let payment: Payment = new Payment(res);
    return payment.get();
  }
  public async payment(
    amount: number,
    currency: string,
    customer: string,
    card: Card,
    metadata?: any
  ): Promise<Object> {
    let request: any = {
      payment_intent_id: '',
      payment_intent: {
        amount,
        currency,
        metadata
      },
      next: 'create',
    };
    if (customer) {
      request.payment_intent.customer = customer;
    }
    console.log('request: ', request);
    
    let res = await this.postHttp('stripe/payment', request);
    console.log(res);
    request.payment_intent_id = res.id;
    if (res.status === 'requires_payment_method') {
      await this.stripe.confirmCardPayment(res.client_secret, {
        payment_method: {
          card: card.element,
          billing_details: {
            name: card.name,
          },
        },
      });
      request.next = 'retrieve';
      res = await this.postHttp('stripe/payment', request);
    }
    if (res.status === 'requires_action') {
      await this.stripe.handleCardAction(res.client_secret);
      request.next = 'confirm';
      res = await this.postHttp('stripe/payment', request);
    }
    let payment: Payment = new Payment(res);
    return payment.get();
  }
  public async subscribe(
    payment_method: string,
    customer: string,
    price: string
  ): Promise<Object> {
    let request = {
      payment_intent_id: '',
      invoice_intent_id: '',
      subscribe_id: '',
      subscribe: {
        customer: customer,
        items: [{ price: price }],
        default_payment_method: payment_method,
      },
      next: 'subscribe',
    };
    let res = await this.postHttp('stripe/subscribe', request);
    request.invoice_intent_id = res.invoice.id;
    request.payment_intent_id = res.paymentIntent.id;
    request.subscribe_id = res.subscribe.id;
    if (res.paymentIntent.status === 'requires_action') {
      await this.stripe.confirmCardPayment(res.paymentIntent.client_secret);
      request.next = 'confirm';
      res = await this.postHttp('stripe/subscribe', request);
    }
    let subscription: Subscription = new Subscription(res);
    return subscription.get();
  }
  public async cancelSubscription(subscription_id: string): Promise<Object> {
    let res = await this.postHttp('stripe/cancelSubscription', subscription_id);
    let subscription: Subscription = new Subscription(res);
    return subscription.get();
  }
  public async postHttp(url: string, data: any): Promise<any> {
    data.type = url;
    console.log(data);
    const firebase = require("firebase");
    require("firebase/functions");
    const api = firebase.functions().httpsCallable('apiPayment');
    let res = await api(data);
    console.log(res);
    return res.data;
  }
}
