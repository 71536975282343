import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { InicioComponent } from "./pages/inicio/inicio.component";
import { ArreglosComponent } from "./pages/arreglos/arreglos.component";
import { DecoracionesComponent } from "./pages/decoraciones/decoraciones.component";
import { SucursalesComponent } from "./pages/sucursales/sucursales.component";
import { ContactoComponent } from "./pages/contacto/contacto.component";
import { OrdenarComponent } from "./pages/ordenar/ordenar.component";
import { TerminosComponent } from "./components/terminos/terminos.component";
import { PersonalizadosComponent } from "./pages/personalizados/personalizados.component";
import { EventosComponent } from "./pages/eventos/eventos.component";
import { OrdenarCompraComponent } from "./pages/ordenar-compra/ordenar-compra.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { CheckoutSuccesComponent } from "./pages/checkout-succes/checkout-succes.component";
import { RastreoComponent } from "./pages/rastreo/rastreo.component";
import { EstadoOrdenComponent } from "./pages/estado-orden/estado-orden.component";
import { AuthGuard } from "./auth.guard";
import { AnalitycGuard } from "./analityc.guard";

const routes: Routes = [
  { path: "", redirectTo: "inicio", pathMatch: "full" },
  { path: "inicio", component: InicioComponent, canActivate: [AuthGuard ,AnalitycGuard] },
  { path: "ordenar/:id", component: OrdenarComponent , canActivate: [AuthGuard,AnalitycGuard] },
  { path: "arreglos", component: ArreglosComponent, canActivate: [AuthGuard,AnalitycGuard] },
  {
    path: "personalizados",
    component: PersonalizadosComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  {
    path: "arreglos/:ocasion",
    component: ArreglosComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  {
    path: "decoraciones",
    component: DecoracionesComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  { 
    path: "eventos", 
    component: EventosComponent, 
    canActivate: [AuthGuard,AnalitycGuard]
   },
  {
    path: "sucursales",
    component: SucursalesComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  { path: "contacto", component: ContactoComponent, canActivate: [AuthGuard,AnalitycGuard] },
  {
    path: "avisodeprivacidad",
    component: TerminosComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  {
    path: "arreglo/:id",
    component: OrdenarCompraComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  { path: "verificar", component: CheckoutComponent, canActivate: [AuthGuard,AnalitycGuard] },
  {
    path: "exito",
    component: CheckoutSuccesComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  {
    path: "rastrear-pedido",
    component: RastreoComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
  {
    path: "estado-orden/:id",
    component: EstadoOrdenComponent,
    canActivate: [AuthGuard,AnalitycGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
