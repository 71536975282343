import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import "firebase/analytics"; 

@Injectable({
  providedIn: "root",
})
export class AnalitycsService {
  
  public analytics = firebase.analytics();
  public viewPage(page: string): void {
    console.log("page: ", page);
    this.analytics.logEvent("page_view", {
      content_id: page,
    });
  }
}
